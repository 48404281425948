import React from "react";
import Container from "./Container";
import { FiPhoneCall } from "react-icons/fi";
import { FaRocketchat } from "react-icons/fa";
export default function Contact() {
  return (
    <Container>
      <div className="w-[100%] items-center justify-center flex flex-col gap-5 py-[4rem]">
        <h1 className="text-[#3fb4e2] text-4xl font-bold tracking-wider">
          Welcome To Our Support. We're Here To Help.
        </h1>
        <span className="text-lg tracking-[0.3em]">
          {" "}
          ALWAYS ON YOUR SIDE WHEN YOU NEED HELP
        </span>
        <div className="w-[100%] items-center flex justify-center px-[5%] mt-10 gap-10">
          <div className="border-[1px] relative flex gap-5 w-[50%] rounded-xl transition-all duration-500 hover:border-[#8cc3e7] p-[3rem]">
            <div className="bg-[#3fb4e2] px-5 py-3  text-md rounded-md h-fit tracking-wider right-[10%] absolute bottom-[-1.5rem] text-white font-bold">
                <h1>
                  <a href="/contact">
                  Know More {'>'}
                  </a>
                  </h1>
            </div>
            <div className="w-100%] flex flex-col gap-5">
              <FiPhoneCall className="text-[4rem]" />
            </div>
            <div className="flex flex-col gap-1">
              <h1 className="text-lg tracking-[0.3em] uppercase">Contact Us</h1>
              <p className="text-[35px] font-bold ">Have Any Doubts?</p>
              <span className="tex-xs mt-5 text-gray-600">
                This Number is Toll Free <br />
                0000 - 1234 - 56789
              </span>
            </div>
          </div>
          <div className="border-[1px] relative flex gap-5 w-[50%] rounded-xl transition-all duration-500 hover:border-[#8cc3e7] p-[3rem]">
            <div className="bg-[#3fb4e2] px-5 py-3  text-md rounded-md h-fit tracking-wider left-[23%] absolute bottom-[-1.5rem] text-white font-bold">
                <h1>Contact {'>'}</h1>
            </div>
            <div className="w-100%] flex flex-col gap-5">
              <FaRocketchat  className="text-[4rem]" />
            </div>
            <div className="flex flex-col gap-1">
              <h1 className="text-lg tracking-[0.3em] uppercase">Online Chat</h1>
              <p className="text-[35px] font-bold ">Live Chat Now</p>
              <span className="tex-xs mt-5 text-gray-600">
              Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo.Lorem ipsum dolor
              </span>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
