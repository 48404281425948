import React from 'react'

import About from './About'

import Mask from './Mask'
import Basic from './Basic'


import Why from './Why'
import Contact from './Contact'

import Footer1 from './Footer1'

import Banner1 from './Banner1'


import Testimonials from '../ABout.jsx/Testimonials'
import About1 from './About1'
import Product from './Product'
import Header from './Header'
import BannerAbove from './BannerAbove'
import CustomSwiper from './Deliver'

export default function HomePage() {
  return (
    <>
  <BannerAbove/>
  <Header/>
     {/* <Banner/> */}
     <Banner1/>
<About/>
<CustomSwiper/>
    {/* <About1/> */}
     {/* <Deliver/> */}
     {/* <Banner1/> */}
     {/* <Product/> */}
     {/* <Featured/> */}
     {/* <About/> */}
     {/* <Prevention/> */}
     <Mask/>
     <Basic/>
     {/* <Protection/> */}
     {/* <Supply/> */}
     {/* <Feature/> */}
     {/* <Arrivals/> */}
     <Testimonials/>
     {/* <Services/> */}
     <Why/>
     <Contact/>
     <Footer1/>
    </>
  )
}
