const data=[
    {
        "id": 1,
        image:'',
        name:'Antibodies Used For Children Health',
        author:'Ramamoorthi M',
        date:'January 11, 2019',
        tag:'Blood Sample,Testing',
        p:[
            {
                id:1,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:2,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:3,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:4,
                content:<b><i>"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"</i></b>
            },
            {
                id:5,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },

        ],
        content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
    },
    {
        "id": 2,
        image:'',
        name:'Antiviral Research And Development',
        author:'Ramamoorthi M',
        date:'January 11, 2024',
        tag:'Virus,Testing',
        p:[
            {
                id:1,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:2,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:3,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:4,
                content:<b><i>"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"</i></b>
            },
            {
                id:5,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },

        ],
        content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
    },
    {
        "id": 3,
        image:'',
        name:'Antiviral Research And Development',
        author:'Ramamoorthi M',
        date:'January 11, 2024',
        p:[
            {
                id:1,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:2,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:3,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:4,
                content:<b><i>"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"</i></b>
            },
            {
                id:5,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },

        ],
        tag:'Virus,Testing',
        content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
    },
    {
        "id": 4,
        image:'',
        name:'Antiviral Research And Development',
        author:'Ramamoorthi M',
        date:'January 11, 2024',
        tag:'Virus,Testing',
        p:[
            {
                id:1,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:2,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:3,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },
            {
                id:4,
                content:<b><i>"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"</i></b>
            },
            {
                id:5,
                content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
            },

        ],
        content:"Antibodies are a critical component of the body’s immune system. They help protect against infections, diseases, and other pathogens. They are produced by B cells, T cells, and NK cells. Antibodies are often found in the blood, saliva, or skin, and they can be found in various forms, including antigens, antibody fragments, and antibody"
    },

]
export default data;