import img1 from '../../Assets/shop-13.webp'
import img2 from '../../Assets/shop-26_mask.webp'
const data=[
    {
        id:1,
        type:'mask',
        category:'new arrials',
        image:img1,
        name:'Surgical Gloves',
        price:'Rs. 4.86'
    },
    {
        id:2,
        type:'mask',
        category:'new arrials',
        image:img1,
        name:'Wet Wipes',
        price:'Rs. 4.65'
    },
    {
        id:3,
        type:'mask',
        category:'new arrials',
        image:img1,
        name:'Paper Napkins',
        price:'Rs. 4.86'
    },
    {
        id:4,
        type:'mask',
        category:'new arrials',
        image:img2,
        name:'N95 Face Mask',
        price:'Rs. 1.58'
    },
    {
        id:5,
        type:'mask',
        category:'new arrials',
        image:img2,
        name:'N99 Face Mask',
        price:'Rs. 1.04'
    },
    {
        id:6,
        type:'mask',
        category:'new arrials',
        image:img2,
        name:'Disposable Mask',
        price:'Rs. 4.08'
    }
]
export default data;