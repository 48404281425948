import React from 'react'
import { TbTruckDelivery } from 'react-icons/tb'
import exper from '../../Assets/experience.svg'
import { FaCircleCheck } from 'react-icons/fa6'
import { FaArrowRight } from 'react-icons/fa'
export default function About1() {
  return (
    <section className='w-[100vw] flex items-center justify-center overflow-hidden'>
      <div className="max-w-[1400px] w-[100%] flex items-center px-[7%] py-[4rem]">
        <div className='w-[100%] flex gap-10 items-center'>
            <div className='w-[50%] relative  flex items-center overflow-hidden'>
            <div className="absolute right-[20%] z-10 top-[10%] flex items-center gap-3 bg-white w-fit p-3 rounded-full">
              <div className="w-[4rem] h-[4rem] bg-[#3fb4e2] flex items-center justify-center rounded-full">
              <img src={exper} alt="" className='w-[3rem]' />
              </div>
              <h1 className="text-lg leading-[1.4rem] font-bold text-[#3fb4e2]">30 Years Of
               <br />Experience</h1>

            </div>
                <div className='w-[100%] flex gap-6'>
                    <div className='w-[66%] h-[30rem] rounded-[60px] overflow-hidden '>
                        <img src={require('../../Assets/01 (1).jpg')} className='h-[100%] w-[100%] object-cover' alt="" />

                    </div>
                    <div className='w-[50%] flex flex-col relative items-center justify-center h-[30rem]  '>
                        <div className="absolute top-0 -z-10 w-[73%]">
                            <img src={require('../../Assets/01.png')} alt="" />
                        </div>
                      <div className="w-[100%] h-[7rem]" />
                        <div className='rounded-full overflow-hidden w-[100%] h-[22rem]'>

                        <img src={require('../../Assets/02.jpg')} className='h-[100%] w-[100%] object-cover' alt="" />
                        </div>
                    </div>

                </div>
            </div>
            <div className="w-[50%] flex flex-col gap-5">
                <h1 className='uppercase text-xl tracking-wider font-bold text-[#3fb4e2]'>About Us</h1>
                <h2 class="site-title text-[#252525] text-[34px] font-bold tracking-wider leading-[2.5rem]">
                                    We Provide Best and Original <span className='text-[#3fb4e2]'>Medical</span> Product For You
                                </h2>
                                <p className='text-[#252525] leading-[1.7rem]'>
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vitae esse quas sint voluptatum, hic provident nostrum! Animi, sed aperiam? Facere doloremque, accusantium corporis illo delectus laboriosam perspiciatis quod repudiandae id!
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa repudiandae magni itaque eligendi aut aspernatur quasi assumenda quaerat sit! Voluptas voluptatibus, fugiat consequatur fugit nobis molestiae aliquid in! Libero, exercitationem?
                                </p>
                                <div className="flex flex-col gap-5 text-[#1a0b36]">
                                    <div className='flex items-center gap-3 '>
                                    <FaCircleCheck className='text-3xl ' />
                                    <h1 className='font-bold tracking-wider'>
                                    Streamlined Shipping Experience
                                    </h1>
                                    </div>
                                    <div className='flex items-center gap-3 '>
                                    <FaCircleCheck className='text-3xl ' />
                                    <h1 className='font-bold tracking-wider'>
                                    Streamlined Shipping Experience
                                    </h1>
                                    </div>
                                    <div className='flex items-center gap-3 '>
                                    <FaCircleCheck className='text-3xl ' />
                                    <h1 className='font-bold tracking-wider'>
                                    Streamlined Shipping Experience
                                    </h1>
                                    </div>
                                    <div className='flex items-center gap-3 '>
                                    <FaCircleCheck className='text-3xl ' />
                                    <h1 className='font-bold tracking-wider'>
                                    Streamlined Shipping Experience
                                    </h1>
                                    </div>
                                  
                                </div>
                                <div
                   
                   className="flex theme-btn relative overflow-hidden gap-3  hover:bg-[#fd6a6a] duration-200 justify-center   items-center w-fit"
                 > 
                   
                   
                   <h1>Discover More</h1><FaArrowRight />
                 </div>
                 
            </div>
        </div>
      </div>
    </section>
  )
}
