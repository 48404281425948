import React from "react";
import Container from "./Container";

export default function Basic() {
  return (
    <Container>
      <div className="bg1">
        <div className="w-[100%] flex relative z-99 flex-col gap-5 pb-[10rem] pt-[10rem] mt-[-4.5rem]  items-center justify-center">
          <div style={{zIndex:90}} className="bg-[#3fb4e2] z-99 p-[3rem] mr-[20%] flex flex-col gap-4 w-[40%] rounded-xl">
            <h1 className="text-4xl font-semibold tracking-wider  text-white">
              Your time to join us
            </h1>
            <p className="text-md tracking-wider font-bold text-white ">
              Discover your job opportunities and become an important part of
              our future.
            </p>
            <div className="px-4 py-3 text-lg font-bold w-fit rounded-full text-blue-400 bg-white hover:bg-blue-600 hover:border-blue-300 hover:text-white border-[2px]">
              <a href="/career">

              Apply now
              </a>
            </div>
          </div>
          <div className=" absolute right-[15%] z-10 h-[30rem] w-[25rem] rounded-sm overflow-hidden">
            <img src={require('../../Assets/vision-values-career-11.jpeg')} alt="" className="w-[100%] h-[100%] object-cover" />
          </div>
        </div>
      </div>
    </Container>
  );
}
